/* 194 */
.list-container {
  --min-grid: 165px;
  --max-grid: 1fr;

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--min-grid), var(--max-grid))
  );
  gap: 20px;
}

/* 210 */
.list-container-nowrap {
  overflow: hidden;
  grid-template-rows: 1fr;
  row-gap: 0px;
  column-gap: 20px;
  grid-auto-rows: 0px;
}

.list-container-scroll {
  overflow: scroll;
}

@media screen and (max-width: 400px) {
  .list-container {
    --min-grid: 125px;
  }

  .list-container-nowrap {
    column-gap: 10px;
  }
}
