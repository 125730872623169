.player-button {
  border-radius: 50%;
  /* padding: 4px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #56bd40;
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  /* transition: scale 200ms; */
}

.player-button:hover {
  width: 120%;
  height: 120%;
  background-color: #6ae34f;
  cursor: pointer;
  scale: 1.1;
}
