.track-table-title {
  display: flex;
  align-items: center;
}
/* .track-table-title.selected {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
} */

.track-table-title .track-artist-text {
  color: #6c757d;
}

.track-table-title.selected .track-artist-text {
  color: rgba(255, 255, 255, 0.9);
}

.track-table-title .track-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.track-table-title.selected .track-text {
  color: #56bd40;
}

.track-table-main .track-album-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.track-table > .track-table-main {
  grid-area: main;
  padding: 10px;
  display: grid;
  grid-template-columns: 20px minmax(200px, 1fr) minmax(200px, 1fr) 100px;
  align-items: center;
  padding: 0px 15px 0px 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 5px;
}

.track-table > .track-table-main > .track-table-row.selected {
  background-color: rgba(255, 255, 255, 0.05);
}

.track-table-header th {
  padding: 0.5rem 16px;
  text-align: start;
}

.track-table-header th:last-child {
  padding-right: 20px;
}

.track-table-row.not-playable {
  opacity: 0.4;
}

.track-table-row td {
  padding: 0.5rem 16px;
}

.track-table-row td:nth-child(2) {
  padding-left: 20px;
  padding-right: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.track-table-row td:nth-last-child(2) {
  padding-right: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.track-table-row:hover td:not(:first-child):not(:last-child) {
  background-color: rgba(255, 255, 255, 0.05);
}

@media screen and (max-width: 600px) {
  .track-table-row td:first-child,
  .track-table-row td:last-child {
    padding: 0;
  }

  .track-table-header th:first-child,
  .track-table-header th:last-child {
    padding: 0;
  }
}
