.bar-container {
  display: flex;
  align-items: flex-end;
}

.bar {
  flex: 1;
  animation: bounce ease-in-out alternate infinite;
}

.bar:nth-child(1) {
  animation-duration: 0.5s;
}

.bar:nth-child(2) {
  animation-duration: 1s;
}

.bar:nth-child(3) {
  animation-duration: 0.8s;
}

.bar:nth-child(4) {
  animation-duration: 0.4s;
}

.bar:nth-child(5) {
  animation-duration: 0.7s;
}

@keyframes bounce {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
