.player-container {
  /* height: 80px; */
  height: 100%;
  grid-column: span 2;
}

.player {
  color: #fff;
  background-color: #171717;
  /* background-color: #212121; */
  /* display: flex;
  justify-content: space-between; */
  display: grid;
  align-items: center;
  column-gap: 1rem;
  height: 100%;
  grid-template-columns: minmax(120px, 300px) minmax(min-content, 1fr) 250px;
}

.player-icon {
  font-size: 2rem;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.playing-track-container {
  /* flex-basis: max(200px, 20%);
  flex-shrink: 0; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.player-controls-container {
  /* flex-basis: 60%; */
  display: flex;
  justify-content: center;
  max-height: 500px;
  transition: max-height 300ms ease-in-out;
  transition-delay: 50ms;
}

.volume-container {
  /* flex-basis: 20%; */
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 1rem;
  max-height: 500px;
  transition: max-height 300ms ease-in-out;
}

.volume {
  -webkit-appearance: none;
  appearance: none;
  background-color: #56bd40;
  width: 100%;
  height: 8px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 12px;
  /* box-shadow: 0px 1px 10px 1px #121212; */
}
.volume::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  cursor: grabbing;
  background: #fff;
}
.volume-control {
  display: flex;
  align-items: center;
  gap: 10px;
}

.device-control {
  display: flex;
  align-items: center;
  gap: 10px;
}

.player-close-btn {
  display: none;
  text-align: center;
  position: absolute;
  top: -20px;
  right: 20px;
  width: 60px;
  height: 20px;
  border-radius: 60% 60% 0% 0%;
  background-color: #171717;
  border: 1px solid #ccc;
  border-bottom: none;
  user-select: none;
  cursor: pointer;
}

.track-control-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.track-control-container p {
  font-size: 12px;
  color: #ccc;
}

@media screen and (max-width: 600px) {
  .player {
    grid-template-columns: 1fr;
  }

  .player-container:not(.active-player) .playing-track-container {
    display: none;
  }
  .player-container:not(.active-player) .player-close-btn {
    display: none;
  }
  .player-container:not(.active-player) .volume-control {
    display: none;
  }

  .volume-container {
    height: 80px;
    justify-content: space-between;
    padding: 0 20px;
  }

  .player-controls-container {
    padding: 0 20px;
  }

  .player-container {
    position: relative;
  }

  .player-close-btn {
    display: block;
  }

  .player-container {
    border-top: 1px solid #ccc;
  }

  .player-hidden {
    max-height: 0px;
    overflow: hidden;
  }

  .player-container.active-player .controls-hidden {
    max-height: 0px;
    overflow: hidden;
  }

  /* .player-container:not(.active-player) .lyrics-btn {
    visibility: hidden;
  } */

  .player-container:not(.active-player) .lyrics-btn {
    visibility: hidden;
  }
}
