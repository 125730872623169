.search-bar {
  /* border: 1px solid purple; */
  display: flex;
  gap: 1.5rem;
  padding: 10px;
}

.search-bar button {
  flex-basis: 100px;
}
