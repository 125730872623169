.loader-container {
  /* background-color: darkolivegreen; */
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
}

.loader {
  width: 120px;
  height: 75px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #fff;
}

.loader span {
  font-size: 22px;
  text-transform: uppercase;
  margin: auto;
}

.ball {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  animation: bouncer 0.5s alternate infinite;
}

.ball:nth-child(2) {
  animation-delay: 0.16s;
}

.ball:nth-child(3) {
  animation-delay: 0.32s;
}

@keyframes bouncer {
  from {
    transform: scaleX(1.25);
  }
  to {
    transform: translateY(-50px);
  }
}
