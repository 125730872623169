.page-padding {
  padding: 20px 40px 20px 40px;
}

.search-page {
  display: flex;
  flex-direction: column;
}

.scrollable {
  height: 100%;

  overflow: overlay;
}

.scrollable::-webkit-scrollbar-thumb {
  background: #56bd40;
  border-radius: 10px;
}

.scrollable::-webkit-scrollbar {
  width: 0.7rem;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background: #3e822e;
}

.menu {
  padding: 10px;
  font-size: 0.9rem;
  font-weight: 600;
}

.menu__item {
  width: 100%;
  margin: 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.menu hr {
  margin-bottom: 0;
  border-color: rgba(255, 255, 255, 0.08);
}

.menu__item__icon {
  margin-right: 15px;
  margin-bottom: 5px;
}

.search-recents-container::-webkit-scrollbar {
  display: none;
}
