.track-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}
.track-item.selected {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.track-item:hover {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
}

.track-item.selected:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.track-item .track-artist-text {
  color: #6c757d;
}

.track-item.selected .track-artist-text {
  color: rgba(255, 255, 255, 0.9);
}

.track-item.selected .track-text {
  color: #56bd40;
}
