body {
  background-color: #131315;
  user-select: none;
}
.layout {
  height: 100svh;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 1fr min-content;
  background-color: #131315;
  color: white;
  overflow: hidden;
}
