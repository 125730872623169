.footer {
  grid-column: span 2;
  height: 100%;
  /* min-height: 80px; */
  transition: transform 300ms ease-in-out;
}

/* @media screen and (max-width: 600px) {
  .footer {
    background-color: yellow;
  }
} */
