.card-container {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding: 20px;
  padding-bottom: 25px;
  background-color: rgba(45, 45, 45, 0.3);
  border-radius: 8px;
  transition: background-color 250ms ease-in;
  height: 100%;
}
.card-img {
  border-radius: 50%;
  margin-bottom: 10px;
  object-fit: cover;
  width: 100%;
}

.card-img-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.card-play-container {
  position: absolute;
  bottom: 0;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: bottom 500ms, opacity 300ms;
  cursor: pointer;
}

.card-title {
  white-space: nowrap;
}

.card-title,
.card-subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-title {
  position: relative;
  color: white;
  margin: 0;
  font-weight: 600;
}

.card-subtitle {
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
}

.card-container:hover {
  background-color: rgba(45, 45, 45, 0.6);
}

.card-container:hover .card-play-container {
  bottom: 20px;
  opacity: 1;
}

.card-subtitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
/* Category Card */
