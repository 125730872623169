.track-table {
  width: 100%;
  border-spacing: 0px;
}

.track-table-container {
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
}

.track-table > .track-table-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  position: sticky;
  top: 64px;
}

.track-table > .track-table-main {
  grid-area: main;
  padding: 0 40px;
}
