.sider {
  --sider-width: 225px;
  width: var(--sider-width);
  transition: width 300ms;
  overflow: hidden;
  background-color: #0d0d0d;
  display: grid;
  grid-template-rows: min-content 1fr;
}

.sider:hover {
  color: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 768px) {
  .sider {
    --sider-width: 0px;
  }
}
