.search-header {
  position: sticky;
  top: 60px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow: hidden;
  gap: 20px;
  background-color: #131313;
  padding: 10px 40px;
  z-index: 2;
}

.search-tab {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 5px 20px;
  border-radius: 20px;
  text-align: center;
}
.search-tab:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.search-tab.selected {
  background-color: white;
  color: #131313;
}

.search-principal-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin: 10px 0;
  /* padding: 0 40px; */
}

.search-principal {
  flex-basis: 300px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.search-songs {
  flex-basis: 250px;
  flex-grow: 5;
}

.search-list {
  margin: 40px 0;
}

.search-list-container {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)); */
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  overflow: hidden;

  grid-template-rows: 1fr;
  column-gap: 20px;
  grid-auto-rows: 0px;
}

.search-card-delete-btn {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 15px;
  right: 15px;
  text-align: center;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 300ms, transform 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-card-delete-btn:hover {
  transform: scale(1.2);
  background-color: rgba(0, 0, 0, 0.9);
}
