@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap");

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0px;
}

body * {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  padding: 0;
  margin: 0;
}
